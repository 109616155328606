import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Intro from "../components/intro";
import Info from "../components/info";
import FeatureItem from "../components/feature-item"
import AboutMe from "../components/about-me"
import Mission from "../components/mission"
import CallToAction from "../components/call-to-action"
import HowItWorks from "../components/how-it-works"
import FAQ from "../components/faq"
import FAQItem from "../components/faq-item"
import Features from "../components/features";
import Codex from "../components/codex";

const PrivatePage = ({ data }) => {
  const color = "yellow";

  const { contentfulPrivatePage, contentfulDieReiseExpertin, contentfulFirmenKodex, allContentfulGruppePrivatFaq } = data;

  const faqItems = allContentfulGruppePrivatFaq.edges.map(faq => faq.node);
  
  const { 
    label,
    titel,
    heroPrimaryButtonText,
    heroPrimaryButtonUrl,
    heroSecondaryButtonText,
    heroSecondaryButtonUrl,
    heroBeschreibung,
    introTitel,
    introBeschreibung,
    introList,
    introPrimaryButtonText,
    introPrimaryButtonUrl,
    introSecondaryButtonText,
    introSecondaryButtonUrl,
    leistungenTitel,
    leistungenItems,
    reiseExpertinText,
    reiseExpertinProfil,
    missionTitel,
    missionText: { missionText },
    fairnessTitel,
    fairnessText,
    fairnessLink,
    fairnessButtonText
  } = contentfulPrivatePage;

  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      <div className="bg-blue-200">
          <Intro
            title={titel}
            label={label}
            heroPrimaryButtonText={heroPrimaryButtonText}
            heroPrimaryButtonUrl={heroPrimaryButtonUrl}
            heroSecondaryText={heroSecondaryButtonText}
            heroSecondaryButtonUrl={heroSecondaryButtonUrl}
            imgSrc={require("../images/png/waterfall@2x.png")}
            color={color}
          >
            {heroBeschreibung.heroBeschreibung}
          </Intro>
          <Info
            title={introTitel}
            desc = {introBeschreibung.introBeschreibung}
            primaryButtonText = {introPrimaryButtonText}
            primaryButtonUrl = {introPrimaryButtonUrl}
            secondaryButtonText = {introSecondaryButtonText}
            secondaryButtonUrl = {introSecondaryButtonUrl}
            img = {require("../images/png/mountain@2x.png")}
          >
            {introList.map(item => <FeatureItem>{item}</FeatureItem>)}
          </Info>
          <Features title={leistungenTitel}>
            {leistungenItems.map(item => <FeatureItem>{item}</FeatureItem>)}
          </Features>

          <FAQ>
            {faqItems.map(faq => (
                <FAQItem title={faq.frage}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: faq.antwort.childMarkdownRemark.html,
                    }}
                  />
                </FAQItem>
              ))
            }
          </FAQ>
          <HowItWorks />
          <div className="bg-white">
            <div className="md:flex max-w-4xl mx-auto">
              <AboutMe
                reiseExpertinText={reiseExpertinText}
                contentfulDieReiseExpertin={contentfulDieReiseExpertin}
                reiseExpertinProfil={reiseExpertinProfil} />
              <Mission
                missionTitel={missionTitel}
                missionText={missionText} />
            </div>
          </div>
          <Codex
            contentfulFirmenKodex={contentfulFirmenKodex}
            color={color}
          />
          <CallToAction
            fairnessTitel={fairnessTitel}
            fairnessText={fairnessText}
            fairnessLink={fairnessLink}
            fairnessButtonText={fairnessButtonText}></CallToAction>
        </div>
    </Layout>
  );
};

export default PrivatePage;

export const pageQuery = graphql`query PrivateQuery {
  contentfulPrivatePage {
    label
    titel
    heroPrimaryButtonText
    heroPrimaryButtonUrl
    heroBeschreibung {
      heroBeschreibung
    }
    introTitel
    introBeschreibung {
      introBeschreibung
    }
    introList
    leistungenTitel
    leistungenItems
    introPrimaryButtonText
    introPrimaryButtonUrl
    reiseExpertinText {
      reiseExpertinText
    }
    reiseExpertinProfil {
      file {
        url
      }
    }
    missionTitel
    missionText {
      missionText
    }
    fairnessTitel
    fairnessText {
    	fairnessText
    }
    fairnessLink
    fairnessButtonText
  },
  contentfulDieReiseExpertin {
    titel
    name
    eMail
    nummer
    link
    profilBild {
      file {
        url
        fileName
        contentType
      }
    }
  },
  contentfulFirmenKodex {
    titel
    kodexEintrag
  }
  allContentfulGruppePrivatFaq {
    edges {
      node {
        frage
        antwort {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
}`
